import { Box, Heading, Text } from "@chakra-ui/react";
import { Post } from "components/posts/Post";
import { useOutletContext } from "react-router-dom";

export default function Home() {
    const [user, CHATS, POSTS, ORGDATA, ORGCHAT, EVENTS, ANNOUNCEMENTS] = useOutletContext();



    return (
        <Box px="4" align="center">
            <Heading size="lg">Recent Announcement</Heading>
            {!ANNOUNCEMENTS.isLoading && ANNOUNCEMENTS.announcements.docs[0] ? <Post key={ANNOUNCEMENTS.announcements.docs[0].id} currentUser={user} post={ANNOUNCEMENTS.announcements.docs[0].data()} id={ANNOUNCEMENTS.announcements.docs[0].id} orgData={ORGDATA.data} /> : <Text>No Announcements</Text>}
            <Heading size="lg" mt={5}>Recent Posts</Heading>
            {!POSTS.isLoading && 
                <>
                    {POSTS.posts.docs[0] ? <Post key={POSTS.posts.docs[0].id} currentUser={user} post={POSTS.posts.docs[0].data()} id={POSTS.posts.docs[0].id} orgData={ORGDATA.data} /> : <Text>No Posts</Text>}
                    {POSTS.posts.docs[1] && <Post key={POSTS.posts.docs[1].id} currentUser={user} post={POSTS.posts.docs[1].data()} id={POSTS.posts.docs[1].id} orgData={ORGDATA.data} />}
                    {POSTS.posts.docs[2] && <Post key={POSTS.posts.docs[2].id} currentUser={user} post={POSTS.posts.docs[2].data()} id={POSTS.posts.docs[2].id} orgData={ORGDATA.data} />}
                </>
            }
        </Box>
    );
}