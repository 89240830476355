import { Box, Spinner } from "@chakra-ui/react";
import { useComments } from "hooks/comments";
import Comment from "./Comment";
import InfiniteScroll from "react-infinite-scroll-component";

export default function CommentList({currentUser, orgData, comments, hasMore, setLimit }) {
  return (
    <InfiniteScroll
      dataLength={comments.docs.length}
      next={() => {setLimit(prev => prev + 20)}}
      hasMore={hasMore}
      loader={<Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='md'/>}
    >
      {comments.docs.map(comment=> <Comment key={comment.id} comment={comment} orgData={orgData} currentUser={currentUser}/>)}
    </InfiniteScroll> 
  );
}
