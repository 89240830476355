import { HOME, LOGIN } from "lib/routes";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "lib/constants";

export default function Root() {
    const navigate = useNavigate();
    const user = useContext(UserContext);

    useEffect(() => {
        if(!user) {
            navigate(LOGIN);
        } else if(user) {
            navigate(HOME);
        }
    }, [user]);
    
   return (
    <>root</>
   );
}