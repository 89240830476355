import { Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";

export default function Topbar({user, id}) {
  return (
    <Flex
      h="81px" w="100%"
      align="center"
      borderBottom="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      direction="row"
      p={5}
    >
     
      <HStack spacing='12px'>
        <Avatar user={user} id={id} size="md"/>
        <Text>{`${user.firstName} ${user.lastName}`}</Text>
      </HStack>

    </Flex>
  )
}