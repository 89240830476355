import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    FormErrorMessage,
  } from '@chakra-ui/react';
import { useForgot } from 'hooks/auth';
import { emailValidate } from 'lib/form-validate';
import { useForm } from 'react-hook-form';
  

  export default function Forgot() {
    const { forgot, isLoading } = useForgot();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function handleForgot(data) {
        await forgot({
            email: data.email,
        })
    }

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <form onSubmit={handleSubmit(handleForgot)}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Forgot your password?
          </Heading>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}>
            You&apos;ll get an email with a reset link
          </Text>
          <FormControl isInvalid={errors.email} id="email">
            <Input
              placeholder="your-email@example.com"
              _placeholder={{ color: 'gray.500' }}
              type="email"
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              type='submit'
              isLoading={isLoading}
              loadingText='Sending Request'
            >
              Request Reset
            </Button>
          </Stack>
        </Stack>
        </form>
      </Flex>
    );
  }