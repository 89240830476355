import { 
  Flex, 
  IconButton,
  useColorModeValue
} from "@chakra-ui/react";
import {
  FaRegThumbsUp,
  FaThumbsUp,
  FaComment,
  FaRegComment,
  FaTrash,
} from "react-icons/fa";
import { useToggleLike, useDeletePost } from "hooks/posts";
import { Link } from "react-router-dom";
import { COMMENTS } from "lib/routes";

export default function Actions({ currentUser, post, id, orgData }) {
  const { likes, user, commentsLength } = post;

  const isLiked = likes.includes(currentUser.id);
  const config = {
    id,
    isLiked,
    uid: currentUser.id,
  };

  const { toggleLike, isLoading: likeLoading } = useToggleLike(config);
  const { deletePost, isLoading: deleteLoading } = useDeletePost(id);

  return (
    <Flex p="2">
      <Flex alignItems="center">
        <IconButton
          onClick={toggleLike}
          isLoading={likeLoading}
          size="md"
          colorScheme={useColorModeValue('orange', 'messenger')}
          variant="ghost"
          icon={isLiked ? <FaThumbsUp /> : <FaRegThumbsUp />}
          isRound
        />
        {likes.length}
      </Flex>
      
      <Flex alignItems="center" p="2">
        <IconButton
          as={Link}
          to={`${COMMENTS}/${id}`}
          size="md"
          colorScheme={useColorModeValue('yellow', 'orange')}
          variant="ghost"
          icon={commentsLength == 0 ? <FaRegComment /> : <FaComment />}
          isRound
        />
        {commentsLength}
      </Flex>
      {(orgData.admins.includes(currentUser.id) || user.id === currentUser.id) && (
        <IconButton
          ml="auto"
          onClick={deletePost}
          isLoading={deleteLoading}
          size="md"
          colorScheme="red"
          variant="ghost"
          icon={<FaTrash />}
          isRound
        />
      )}
    </Flex>
  );
}
