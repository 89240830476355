export const nameValidate = {
  required: {
    value: true,
    message: "Please enter name",
  },
  pattern: {
    value: /^[A-Za-z]+$/,
    message: "Invalid Name",
  }
};

export const phoneValidate = {
  required: {
    value: true,
    message: "Please enter phone number",
  },
  minLength: {
    value: 10,
    message: "Phone number must be 10 digits long not less",
  },
  maxLength: {
    value: 10,
    message: "Phone number must be 10 digits long not more",
  },
  pattern: {
    value: /^[0-9]*$/,
    message: "Phone number must be only digits",
  },
};

export const emailValidate = {
  required: {
    value: true,
    message: "Please enter an email address",
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Email address is not valid",
  },
};

export const passwordValidate = {
  required: {
    value: true,
    message: "Please enter password",
  },
  minLength: {
    value: 8,
    message: "Password must be at least 8 characters long",
  },
};

export const birthdayValidate = {
  required: {
    value: true,
    message: "Please enter your date of birth",
  }
};

export const genderValidate = {
  required: {
    value: true,
    message: "Please choose a gender",
  }
};

export const zipValidate = {
  required: {
    value: true,
    message: "Please enter your zip code",
  },
  pattern: {
    value: /^[0-9]{5}(?:-[0-9]{4})?$/,
    message: "Zip code is not valid"
  }
};

export const cityValidate = {
  required: {
    value: true,
    message: "Please enter your city",
  },
  pattern: {
    value: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
    message: "City is not valid"
  }
};

export const profilePicValidate = {
  required: {
    value: true,
    message: "Please provide a profile picture",
  }
};

export const describesValidate = {
  required: {
    value: true,
    message: "Choose at least one of the options",
  }
};

export const bioValidate = {
  required: {
    value: true,
    message: "Tell the ummah a little about yourself",
  }
};

export const universityValidate = {
  required: {
    value: true,
    message: "Please choose the university you went to",
  }
};

export const degreeTypeValidate = {
  required: {
    value: true,
    message: "Please choose the highest level of education you have",
  }
};

export const degreeValidate = {
  required: {
    value: true,
    message: "Please choose the degree you got correlating to your highest education level",
  }
};