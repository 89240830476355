import { useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import { arrayRemove, arrayUnion, collection, doc, getCountFromServer, limit, orderBy, query, setDoc, where, writeBatch } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useGlobalState } from "lib/constants";
import { db, storage } from "lib/firebase";
import { useEffect, useState } from "react";
import { useCollection, useDocument, useDocumentData } from "react-firebase-hooks/firestore";

export function useToggleAttending({ id, isAttending, isWaitlist, full, user }) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");

  const userData = user.data();

  async function toggleAttending() {
    setLoading(true);
    const batch = writeBatch(db);
    const docRef = doc(db, `Organizations/${organization}/events`, id);
    if(!full) {
      if(!isAttending || window.confirm("Are you sure you don't want to attend this event?\nYou are not guaranteed a refund.")) {
        batch.update(docRef, {
          attending: isAttending ? arrayRemove(user.id) : arrayUnion(user.id),
        });
      }
      if(!isAttending) {
        batch.update(doc(db, `Organizations/${organization}/events/${id}/private/chat`), {
          [`attendingUsersDataMap.${user.id}`]: {firstName: userData.firstName, lastName: userData.lastName, profilePic: userData.profilePic},
        });
      }
    } else {
      batch.update(doc(db, `Organizations/${organization}/events/${id}`), {
        waitlist: isWaitlist ? arrayRemove(user.id) : arrayUnion(user.id),
      });
    }
    batch.commit();
    setLoading(false);
  }

  return { toggleAttending, isLoading };
}

export function useAddEvent() {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  async function addEvent(data) {
    setLoading(true);
    let fileRef;
    let eventURL;
    try {
    const id = uuidv4()

    fileRef = ref(storage, `Organizations/${organization}/eventImages/${id}`);
    await uploadBytes(fileRef, data.eventImage[0]);

    eventURL = await getDownloadURL(fileRef);

    const startDateTime = new Date(data.startDateTime);

    const batch = writeBatch(db);

    batch.set(doc(db, `Organizations/${organization}/events/${id}`), {
      access: data.access,
      attending: [],
      waitlist: [],
      max: data.maxPeople ? data.maxPeople : 0,
      title: data.title,
      location: data.location,
      address: data.address,
      full: false,
      startDateTime,
      image: eventURL,
    });

    batch.set(doc(db, `Organizations/${organization}/events/${id}/private/chat`));

    await batch.commit();
//TODO: Test this

    let milli = 0;
      if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
      }
      setTimeout(function() {
      toast({
      id: "toasts",
      title: "Event added successfully!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    }, milli);
  } catch (error) {
    if(eventURL) deleteObject(fileRef);

    let milli = 0;
    if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
    }
    setTimeout(function() { 
        toast({
            id: "toasts",
            title: "Event not added",
            description: error.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
        });
    }, milli);
  } finally {
    setLoading(false);
  }
  }

  return { addEvent, isLoading };
}

export function useEventChat(id) {
  const [organization] = useGlobalState("organization");
  const [eventChat, isLoading, error] = useDocumentData(doc(db, `Organizations/${organization}/events/${id}/private/chat`));

  if (error && error.code == 'permission-denied') {} else if(error) {console.error(error)}

  return {eventChat, isLoading};
}

export function useEvent(id) {
  const [organization] = useGlobalState("organization");
  const [event, isLoading, error] = useDocument(doc(db, `Organizations/${organization}/events`, id));
  if(error) console.error(error);

  return { event, isLoading };
}

export function useEvents(inOrg) {
    const [organization] = useGlobalState("organization");
    const [num, setLimit] = useState(20);
    const [hasMore, setMore] = useState(false);
    const q = inOrg ? query(collection(db, `Organizations/${organization}/events`), orderBy("startDateTime", "asc"), limit(num)) 
                    : query(collection(db, `Organizations/${organization}/events`), where("access", "==", true), orderBy("startDateTime", "asc"), limit(num));
    const [events, isLoading, error] = useCollection(q);
  
    const [max, setMax] = useState(0);
  
    useEffect(() => {
      async function fetch() {
        const q = inOrg ? query(collection(db, `Organizations/${organization}/events`), orderBy("startDateTime", "asc")) 
                    : query(collection(db, `Organizations/${organization}/events`), where("access", "==", "public"), orderBy("startDateTime", "asc"));
        setMax((await getCountFromServer(q)).data().count);
        setMore(max ? true : false);
      }
  
      fetch();
    }, [organization]);
  
    useEffect(() => {
      if(num >= 500 || max != 0 && num >= max) {
        setMore(false);
      }
    }, [num, max]);
  
    if(error) console.error(error);  
  
    return { events, max, hasMore, setLimit, isLoading };
  }