import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, HStack, IconButton, Spinner, Text, VStack, useBreakpointValue, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { useEffect, useRef } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Bottombar from "./Bottombar";
import { useEvent, useEventChat } from "hooks/events";
import { FiMenu } from "react-icons/fi";

export default function EventChat() {
    const [user] = useOutletContext();

    const {id: eventID} = useParams();
    const {eventChat, isLoading: eventChatLoading} = useEventChat(eventID);
    const {event, isLoading: eventLoading} = useEvent(eventID);

    const userColor = useColorModeValue("yellow.100", "orange.400");
    const otherColor = useColorModeValue("gray.100", "gray.600");

    const bottomOfChat = useRef(null);

    const hoverColor = useColorModeValue("gray.100", "gray.700");
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const isDrawerSidebar = useBreakpointValue({ base: true, md: false });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const messages = eventChat?.messages?.reduce((result, message) => {
      if(result.length === 0 || result[result.length - 1][0].sender !== message.sender) {
        result.push([]);
      }
      result[result.length - 1].push(message);
      return result;
    }, []);
  
    useEffect(() => {
      if(bottomOfChat.current) {
        setTimeout(
          bottomOfChat.current.scrollIntoView({
          block: 'start',
        }), 100);
      }
    }, [messages]);

    return (
      <Flex h='85vh'>
        {isDrawerSidebar ? (
      <>
    <IconButton
      icon={<FiMenu />}
      
      onClick={onOpen}
    />
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
          <Flex overflowY="auto" direction="column" sx={{scrollbarWidth: "none"}} flex={1} >
        {!eventLoading && !eventChatLoading && event.data().attending.map(uid => {
          const userData = eventChat.attendingUsersDataMap[uid];
          
          return (
          <Flex
          key={uid}
          p={3}
          align="center"
          _hover={{ bg: hoverColor, cursor: "pointer" }}
          >
          <HStack spacing="12px">
            <Avatar id={uid} user={userData} size="md" />
            <Text>{`${userData.firstName} ${userData.lastName}`}</Text>
          </HStack>
        </Flex>
        )})}
      </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    </>
) : (
    <Flex
      h="100%"
      w="300px"
      borderRight="1px"
      borderRightColor={borderColor}
      direction="column"
    >
      <Flex overflowY="auto" direction="column" sx={{scrollbarWidth: "none"}} flex={1} >
      {!eventLoading && !eventChatLoading && event.data().attending.map(uid => {
          const userData = eventChat.attendingUsersDataMap[uid];
          
          return (
          <Flex
          key={uid}
          p={3}
          align="center"
          _hover={{ bg: hoverColor, cursor: "pointer" }}
          >
          <HStack spacing="12px">
            <Avatar id={uid} user={userData} size="md" />
            <Text>{`${userData.firstName} ${userData.lastName}`}</Text>
          </HStack>
        </Flex>
        )})}
      </Flex>
    </Flex>)}

        <Flex flex={1} direction="column">
  
          <Flex flex={1} direction="column" pt={4} mx={5} overflowY="auto" sx={{scrollbarWidth: "none"}}>
            {(!eventLoading && !eventChatLoading) ? messages?.map((block, blockIndex) => {
              const sender = block[0].sender !== user.id;
              const oUserData = eventChat.attendingUsersDataMap[block[0].sender];

              return (
                <VStack key={`${blockIndex}-block`} p={3} m={1}>
                  {sender && 
                    <HStack alignSelf="flex-start">
                      <Avatar alignSelf="flex-start" user={oUserData} id={block[0].sender} size="xs"/>
                      <Text>{oUserData.firstName} {oUserData.lastName}</Text>
                    </HStack>
                  }
                  {block.map((msg, msgIndex) => (
                    <HStack spacing='12px' key={`${blockIndex}-${msgIndex}`} alignSelf={sender ? "flex-start" : "flex-end"} bg={sender ? otherColor : userColor} w="fit-content" minWidth="100px" borderRadius="lg" p={3} m={1}>
                      <Text>{msg.text}</Text>
                    </HStack>
                  ))}
                </VStack>
              )
            }) : <Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='xl'/>}
            <div ref={bottomOfChat}/>
          </Flex>
  
          <Bottombar user={user} eventID={eventID}/>
        </Flex>
      </Flex>
    )
  }