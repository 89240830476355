import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import { PROTECTED } from "lib/routes";
import { Link } from "react-router-dom";

export default function Avatar({ user, id, size = "xl", overrideAvatar = null }) {
  try {
    return (
      <ChakraAvatar
        as={Link}
        to={`${PROTECTED}/profile/${user.id?user.id:id}`}
        name={`${user.data().firstName} ${user.data().lastName}`}
        size={size}
        src={overrideAvatar || user.data().profilePic}
        _hover={{ cursor: "pointer", opacity: "0.8" }}
      />
    );
  } catch {
    return (
      <ChakraAvatar
        as={Link}
        to={`${PROTECTED}/profile/${user.id?user.id:id}`}
        name={`${user.firstName} ${user.lastName}`}
        size={size}
        src={overrideAvatar || user.profilePic}
        _hover={{ cursor: "pointer", opacity: "0.8" }}
      />
    );
  }
}
