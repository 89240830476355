import { Box, Spinner, Text } from "@chakra-ui/react";
import { Post } from "./Post";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PostsList({isAnnouncements, currentUser, posts, hasMore, setLimit, orgData }) {

  return (
    <Box px="4" align="center">
      {posts.docs.length === 0 ? (
        <Text textAlign="center" fontSize="xl">
          No {isAnnouncements ? "announcements" : "posts"} yet... Feeling a little lonely here.
        </Text>
      ) : (
        <InfiniteScroll
          dataLength={posts.docs.length}
          next={() => {setLimit(prev => prev + 20)}}
          hasMore={hasMore}
          loader={<Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='md'/>}
        >
          {posts.docs.map(post => <Post key={post.id} currentUser={currentUser} id={post.id} post={post.data()} orgData={orgData}/>)}
        </InfiniteScroll> 
     )}
    </Box>
  );
}
