import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useColorMode,
} from '@chakra-ui/react';
import { useLogin } from 'hooks/auth';
import { emailValidate, passwordValidate } from 'lib/form-validate';
import { FORGOT, REGISTER } from 'lib/routes';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from "react-router-dom";
  
export default function Login() {
    const { login, isLoading } = useLogin();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: localStorage.getItem("myapp-email") || "",
            password: localStorage.getItem("myapp-password") || "",
            checkbox: localStorage.getItem("myapp-checkbox") || "",
        }
    });
    const {colorMode, toggleColorMode} = useColorMode();

    async function handleLogin(data) {
        const success = await login({
            email: data.email,
            password: data.password,
        });
        if(success) {
            if(data.checkbox == "remember") {
                localStorage.setItem("myapp-email", data.email); 
                localStorage.setItem("myapp-password", data.password);
                localStorage.setItem("myapp-checkbox", data.checkbox);
            } else {
                localStorage.setItem("myapp-email", ""); 
                localStorage.setItem("myapp-password", "");
                localStorage.setItem("myapp-checkbox", "");
            }
        }
    }

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
            <Text onClick={toggleColorMode} fontSize={'lg'} color={'gray.600'}>
              to enjoy all of our cool features ✌️
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <form onSubmit={handleSubmit(handleLogin)}>
                    <FormControl isInvalid={errors.email} py="2">
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        placeholder="user@email.com"
                        {...register("email", emailValidate)}
                    />
                    <FormErrorMessage>
                        {errors.email && errors.email.message}
                    </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.password} py="2">
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="password123"
                        {...register("password", passwordValidate)}
                    />
                    <FormErrorMessage>
                        {errors.password && errors.password.message}
                    </FormErrorMessage>
                    </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox value="remember" {...register("checkbox")}>Remember me</Checkbox>
                  <Link 
                    as={RouterLink}
                    to={FORGOT}
                    color={'blue.400'}
                    >
                    Forgot password?
                    </Link>
                </Stack>
                <Button
                  bg={'yellow.400'}
                  color={'white'}
                  _hover={{
                    bg: 'yellow.500',
                  }}
                  type = 'submit'
                  isLoading={isLoading}
                  loadingText='Signing In'
                >
                  Sign In
                </Button>  
              </Stack>
              </form>

              <Text fontSize="xlg" align="center" mt="6">
          Don't have an account?{" "}
          <Link
            as={RouterLink}
            to={REGISTER}
            color={'blue.400'}
          >
            Register
          </Link>{" "}
          instead!
        </Text>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }