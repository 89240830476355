import { Flex, HStack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import Bottombar from "./Bottombar";

export default function OrgChat() {
    const [user, CHATS, POSTS, ORGDATA, ORGCHAT] = useOutletContext();

    const userColor = useColorModeValue("yellow.100", "orange.400");
    const otherColor = useColorModeValue("gray.100", "gray.600");

    const bottomOfChat = useRef(null);

    const messages = ORGCHAT.orgChat?.messages.reduce((result, message) => {
      if(result.length === 0 || result[result.length - 1][0].sender !== message.sender) {
        result.push([]);
      }
      result[result.length - 1].push(message);
      return result;
    }, []);
  
    useEffect(() => {
      if(bottomOfChat.current) {
        setTimeout(
          bottomOfChat.current.scrollIntoView({
          block: 'start',
        }), 100);
      }
    }, [messages]);

    return (
      <Flex h='85vh'>
        <Flex flex={1} direction="column">
  
          <Flex flex={1} direction="column" pt={4} mx={5} overflowY="auto" sx={{scrollbarWidth: "none"}}>
            {messages?.map((block, blockIndex) => {
              const sender = block[0].sender !== user.id;
              const oUserData = ORGDATA.data?.memberDataMap[block[0].sender];

              return (
                <VStack key={`${blockIndex}-block`} p={3} m={1}>
                  {sender && 
                    <HStack alignSelf="flex-start">
                      <Avatar alignSelf="flex-start" user={oUserData} id={block[0].sender} size="xs"/>
                      <Text>{oUserData.firstName} {oUserData.lastName}</Text>
                    </HStack>
                  }
                  {block.map((msg, msgIndex) => (
                    <HStack spacing='12px' key={`${blockIndex}-${msgIndex}`} alignSelf={sender ? "flex-start" : "flex-end"} bg={sender ? otherColor : userColor} w="fit-content" minWidth="100px" borderRadius="lg" p={3} m={1}>
                      <Text>{msg.text}</Text>
                    </HStack>
                  ))}
                </VStack>
              )
            })}
            <div ref={bottomOfChat}/>
          </Flex>
  
          <Bottombar user={user}/>
        </Flex>
      </Flex>
    )
  }