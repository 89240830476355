import { Box, Flex, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { formatDistanceToNow } from "date-fns";
import { useDeleteComment } from "hooks/comments";
import { FaTrash } from "react-icons/fa";

export default function Comment({ comment, orgData, currentUser }) {
  const { text, uid, date, postID, userData } = comment.data();
  const { deleteComment, isLoading: deleteLoading } = useDeleteComment(comment.id, postID);
  
  return (
    <Box px="4" py="2" maxW="600px" mx="auto" textAlign="left">
      <Flex pb="2">
        <Avatar user={userData} size="sm" />
        <Box flex="1" ml="4">
          <Flex borderBottom="1px solid" borderColor={useColorModeValue("yellow.100", "orange")} pb="2">
            <Box>
              <Text>{`${userData.firstName} ${userData.lastName}`}</Text>
              <Text fontSize="xs" color="gray.500">
                {formatDistanceToNow(date?date.toDate():new Date())} ago
              </Text>
            </Box>
            {(orgData.admins.includes(currentUser.id) || uid === currentUser.id) && (
              <IconButton
                size="sm"
                ml="auto"
                icon={<FaTrash />}
                colorScheme="red"
                variant="ghost"
                isRound
                onClick={deleteComment}
                isLoading={deleteLoading}
              />
            )}
          </Flex>
          <Box pt="2" fontSize="sm">
            <Text>{text}</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
