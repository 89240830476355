import { doc } from "firebase/firestore";
import { useGlobalState } from "lib/constants";
import { db } from "lib/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

export function useAllOrganizationsData() {
    const [data, isLoading, error] = useDocumentData(doc(db, 'Organizations', 'Data'));
    if(error) console.error(error);

    return {data, isLoading};
}

export function useCurrentOrganizationData() {
    const [organization] = useGlobalState("organization");
    const [data, isLoading, error] = useDocumentData(doc(db, 'Organizations', organization));
    if(error) console.error(error);

    return {data, isLoading};
}

export function useOrgChat() {
    const [organization] = useGlobalState("organization");
    const [orgChat, isLoading, error] = useDocumentData(doc(db, `Organizations/${organization}/private/chat`));

    if (error && error.code == 'permission-denied') {} else if(error) {console.error(error)}

    return {orgChat, isLoading};
}