import { useState } from "react";
import { FormControl, Input, Button, useToast } from "@chakra-ui/react";
import { updateDoc, doc, arrayUnion, Timestamp } from "firebase/firestore";
import { db } from "lib/firebase";
import { useGlobalState } from "lib/constants";

export default function Bottombar({user}) {
  const [input, setInput] = useState("");
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  const sendMessage = (e) => {
    e.preventDefault();
    
    if(!input.trim()) {
      let milli = 0;
      if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
      }
      setTimeout(function() {
        toast({
          id: "toasts",
          title: "Cannot send empty message",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        return;
      }, milli);
    } else {
      const timestamp = Timestamp.now();
      const data = {
        text: input.trim(),
        sender: user.id,
        timestamp: timestamp,
      }

      updateDoc(doc(db, `Organizations/${organization}/private/chat`), {
        messages: arrayUnion(data),
      });

      setInput("");
    }
  }

  return (
    <FormControl
      p={3}
      onSubmit={sendMessage}
      as="form"
    >
      <Input placeholder="Type a message..." autoComplete="off" onChange={e => setInput(e.target.value)} value={input} />
      <Button type="submit" hidden>Submit</Button>
    </FormControl>
  )
}