import { doc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { useDocumentOnce } from "react-firebase-hooks/firestore";

export function useUniversities() {
    const [Universities, setUniversities] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response = await fetch(
                'https://parseapi.back4app.com/classes/Usuniversitieslist_University?limit=3300&order=name&keys=name',
                {
                  headers: {
                    'X-Parse-Application-Id': 'SbP6hAJqhAN6cZ4ue10EPrjKJHaQRB6aDqteWw2w', // This is your app's application id
                    'X-Parse-REST-API-Key': 'OqbCVZx7WXDfkWCYPm7tyKlKb5FgjwLPFaEVxzOu', // This is your app's REST API key
                  }
                }
            );
            const data = await response.json();
                
            const temp = data.results.map(result => result.name);
            setUniversities(temp);

            setLoading(false);
        }

        fetchData();
    }, []);

    return { Universities, isLoading };
}

export function useProfileTags() {
  const [ProfileTags, isLoading, error] = useDocumentOnce(doc(db, 'tags', 'profile'));
  if(error) console.error(error);

  return { ProfileTags, isLoading };
}