import { Box, Spinner } from "@chakra-ui/react";
import { useOutletContext, useParams } from "react-router-dom";
import NewComment from "./NewComment";
import CommentList from "./CommentList";
import { useGlobalState } from "lib/constants";
import { usePost } from "hooks/posts";
import { Post } from "components/posts/Post";
import { useComments } from "hooks/comments";

export default function Comments() {
  const { id } = useParams();
  const { post, isLoading: postLoading } = usePost(id);
  const { comments, hasMore, setLimit, isLoading: commentsLoading } = useComments(id);
  const [user, CHATS, POSTS, ORGDATA] = useOutletContext();
  const [organization] = useGlobalState("organization");

  if(postLoading || commentsLoading) return <Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='xl'/>;

  return (
    <Box align="center" pt="50">
      {!ORGDATA.isLoading && user && <Post key={post.id} currentUser={user} id={post.id} post={post.data()} orgData={ORGDATA.data}/>}
      {user.data().organizations.includes(organization) && <NewComment user={user} post={post} />}
      {!ORGDATA.isLoading && <CommentList orgData={ORGDATA.data} comments={comments} hasMore={hasMore} setLimit={setLimit} currentUser={user}/>}
    </Box>
  );
}
