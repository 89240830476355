// Chakra imports
import { Avatar, Button, Center, Divider, Flex, HStack, IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tag, TagLabel, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {MdEmail, MdLocationOn} from "react-icons/md"
import {BsGenderFemale, BsGenderMale} from "react-icons/bs"
import {FaBirthdayCake} from "react-icons/fa"
import {FiMessageCircle} from "react-icons/fi"
import {SlCalender} from "react-icons/sl"
import { useUser } from "hooks/users";
import { usePosts } from "hooks/posts";
import PostsList from "components/posts/PostsList";
import { CHAT } from "lib/routes";
import { addDoc, collection, onSnapshot, serverTimestamp } from "firebase/firestore";
import { db } from "lib/firebase";
import { useUserComments } from "hooks/comments";
import CommentUserList from "components/comments/CommentUserList";

export default function Profile() {
  const {id} = useParams();
  const [currentUser, {chats, isLoading: chatsLoading}, POSTS, ORGDATA] = useOutletContext();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayValue = useBreakpointValue({ base: "icon", md: "text" });
  
  const {posts, max: postsLength, hasMore: pHasMore, setLimit: pSetLimit, isLoading: postsLoading} = usePosts(id);
  const {postComments, max: commentsLength, hasMore: cHasMore, setLimit: cSetLimit, isLoading: commentsLoading} = useUserComments(id);
  const {user, isLoading: userLoading} = useUser(id);
  
  if(userLoading) return <Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='xl'/>;
  const userData = user.data();

  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    const map = new Map();
  
    for (let val of arr1) {
      if (map.has(val)) {
        map.set(val, map.get(val) + 1);
      } else {
        map.set(val, 1);
      }
    }
  
    for (let val of arr2) {
      if (!map.has(val)) {
        return false;
      } else {
        map.set(val, map.get(val) - 1);
      }
    }
  
    for (let count of map.values()) {
      if (count !== 0) {
        return false;
      }
    }
  
    return true;
  }

  return (
    <Stack spacing="5">

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalBody>
      <Center>
        <Image src={userData.profilePic} />
      </Center>
    </ModalBody>
  </ModalContent>
</Modal>

      <Flex p={["4", "6"]} pos="relative" align="center">
        <Avatar 
          size="2xl" 
          name={`${userData.firstName} ${userData.lastName}`} 
          src={userData.profilePic}
          onClick={onOpen}
          _hover={{ cursor: "pointer", opacity: "0.8" }}
        />

        {!chatsLoading && (currentUser.id !== user.id) && (displayValue === "text" ? (
          <Button
            pos="absolute"
            mb="2"
            top="6"
            right="6"
            colorScheme={"yellow"}
            onClick={() => {
              const chat = chats.docs.find(chat => areArraysEqual(chat.data().users, [user.id, currentUser.id]));
          if(!chat) {
            addDoc(collection(db, `chats`), {
              users: [user.id, currentUser.id], 
              userDataMap: {[currentUser.id] : {firstName: currentUser.data().firstName, lastName:currentUser.data().lastName, profilePic: currentUser.data().profilePic}, [user.id] : {firstName: userData.firstName, lastName: userData.lastName, profilePic: userData.profilePic},}, 
              messages: [],
              lastActive: serverTimestamp(),
            }).then(doc => {
              navigate(`${CHAT}/${doc.id}`);   
            });
          } else {
            navigate(`${CHAT}/${chat.id}`);
          } 
            }}
          >
            Send Message
          </Button>
        ):(
          <IconButton
            pos="absolute"
            mb="2"
            top="0"
            right="0"
            colorScheme={"yellow"}
            variant="ghost"
            icon={<FiMessageCircle size={30}/>}
            _hover={{ bg: "yellow.500", color: "white" }}
            onClick={() => {
              const chat = chats.docs.find(chat => areArraysEqual(chat.data().users, [user.id, currentUser.id]));
          if(!chat) {
            addDoc(collection(db, `chats`), {users: [user.id, currentUser.id], lastActive: serverTimestamp()}).then(doc => {
              const unsubscribe = onSnapshot(doc, (docSnapshot) => {
                if (docSnapshot.exists && docSnapshot.data().userDataMap) {
                  setTimeout(() => {
                    navigate(`${CHAT}/${doc.id}`);
                    unsubscribe();
                  }, 10);
                }
              }
            )});
          } else {
            navigate(`${CHAT}/${chat.id}`);
          } 
            }}
          />
        ))}

        <Stack ml="10">
          <HStack><Text fontSize="2xl">{userData.firstName} {userData.lastName} </Text>{userData.gender == "Male" ? <BsGenderMale size={25} color="blue"/> : <BsGenderFemale size={25} color="pink"/>}</HStack>
          {userData.professional && <Text color='gray'>{userData.title} at {userData.company}</Text>}      
        </Stack>

        {/* <EditProfile isOpen={isOpen} onClose={onClose} /> */}
      </Flex>
      <Divider />

      <Tabs position="relative" variant="unstyled">
    <TabList>
      <Tab>Info</Tab>
      <Tab>Posts {postsLength}</Tab>
      <Tab>Comments {commentsLength}</Tab>
    </TabList>
    <TabIndicator
      mt="-1.5px"
      height="2px"
      bg={"yellow.500"}
      borderRadius="1px"
    />
    <TabPanels>
      <TabPanel>
        <Stack spacing={4}>
          <HStack><MdEmail size={25}/><Text >{userData.email}</Text></HStack>
          <HStack><SlCalender size={25}/><Text >Digital Meccan since {userData.joined?.toDate().toLocaleDateString()}</Text></HStack>
          <HStack><FaBirthdayCake size={25}/><Text>{userData.birth?.toDate().toLocaleDateString()}</Text></HStack>
          <HStack><MdLocationOn size={25}/><Text>{userData.city}, {userData.zip}</Text></HStack>
        </Stack>

        <Text mt={10}>About</Text>
        <Text mt={2}>{userData.bio}</Text>
        
        <Text mt={5}>Goals</Text>
        {userData.goals.map(field => (
          <Tag
          key={field}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field}</TagLabel>
        </Tag>)
        )}

        <Text mt={5}>Skills</Text>
        {userData.skills.map(field => (
          <Tag
          key={field}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field}</TagLabel>
        </Tag>)
        )}

        <Text mt={5}>Interests</Text>
        {userData.interests.map(field => (
          <Tag
          key={field}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field}</TagLabel>
        </Tag>)
        )}
      </TabPanel>
      <TabPanel>
      {(postsLoading || ORGDATA.isLoading) ? (
        <Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='xl'/>
      ) : (
        <PostsList isAnnouncements={false} currentUser={user} posts={posts} hasMore={pHasMore} setLimit={pSetLimit} orgData={ORGDATA.data}/>
      )}
      </TabPanel>
      <TabPanel>
      {(commentsLoading || ORGDATA.isLoading) ? (
        <Text>Comments are loading...</Text>
      ) : (
        <CommentUserList currentUser={user} postComments={postComments} hasMore={cHasMore} setLimit={cSetLimit} orgData={ORGDATA.data}/> 
      )}
      </TabPanel>
    </TabPanels>
  </Tabs>
    </Stack>
  );
}