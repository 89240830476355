import { Box, Button, Center, Heading, HStack, Textarea } from "@chakra-ui/react";
import { useAddPost } from "hooks/posts";
import { useGlobalState } from "lib/constants";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import PostsList from "./PostsList";

export default function Posts() {
  const [user, CHATS, {posts, hasMore, setLimit, isLoading}, ORGDATA] = useOutletContext();
  const [organization] = useGlobalState("organization");
  
  const { register, handleSubmit, reset } = useForm();
  const { addPost, isLoading: addingPost } = useAddPost(user, false);
    
  function handleAddPost(data) {
    addPost(data.text);
    reset();
  }

  return (
    <>
      {user?.data().organizations.includes(organization) ? <Box maxW="600px" mx="auto" py="10">
      <form onSubmit={handleSubmit(handleAddPost)}>
        <HStack justify="space-between">
          <Heading size="lg">New Post</Heading>
          <Button
            colorScheme="yellow"
            type="submit"
            isLoading={addingPost}
            loadingText="Loading"
          >
            Post
          </Button>
        </HStack>
        <Textarea
          as={TextareaAutosize}
          resize="none"
          mt="5"
          placeholder="Create a new post..."
          minRows={3}
          {...register("text", { required: true })}
        />
      </form>
    </Box> : <Center mb={10}>
      <Heading size="lg">Posts</Heading>
      </Center>}
      {!isLoading && <PostsList isAnnouncements={false} currentUser={user} posts={posts} hasMore={hasMore} setLimit={setLimit} orgData={ORGDATA.data}/>}
    </>
  );
}
