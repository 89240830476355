import { useToast } from "@chakra-ui/react";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { Timestamp, addDoc, arrayUnion, doc, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { changeOrg } from "lib/constants";
import { auth, db, storage } from "lib/firebase";
import { HOME, LOGIN } from "lib/routes";
import { useState } from "react";
import { useSignOut } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export function useLogin() {
    const [isLoading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
  
    async function login({ email, password }) {
      setLoading(true);
      let returnVal = false;
  
      try {
        await signInWithEmailAndPassword(auth, email, password);
        changeOrg("Mecca");

        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
            toast({
                id: "toasts",
                title: "You are logged in",
                status: "success",
                isClosable: true,
                position: "top",
                duration: 5000,
            });
        }, milli);
        navigate(HOME);
        returnVal = true;
      } catch (error) {
        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
            toast({
                id: "toasts",
                title: "Logging in failed",
                description: error.message,
                status: "error",
                isClosable: true,
                position: "top",
                duration: 5000,
            });
        }, milli);

        returnVal = false;
      } finally {
        setLoading(false);
        return returnVal;
      }
    }
  
    return { login, isLoading };
  }

  export function useRegister() {
    const [isLoading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
  
    async function register({bio, birth, city, company, degree, degreeType, describes, email, firstName, gender, goals, gradYear, interests, lastName, mentor, password, professional, profilePic, skills, title, university, volunteer, zip}) {
        setLoading(true);
        let res;
        let fileRef;
        let avatarURL;
        try {
          if(gender != 1 && gender != 2) {
            throw new Error("Enter a valid gender");
          }
  
          res = await createUserWithEmailAndPassword(auth, email, password);
  
          fileRef = ref(storage, "avatars/" + res.user.uid);
          await uploadBytes(fileRef, profilePic[0]);

          avatarURL = await getDownloadURL(fileRef);

          const birthDate = new Date(birth);

          const batch = writeBatch(db);

          batch.set(doc(db, "users", res.user.uid), {
            bio: bio,
            birth: birthDate, 
            city: city, 
            company: company ? company : null,
            degree: degree,
            degreeType: degreeType, 
            describes: describes, 
            email: email, 
            firstName: firstName, 
            goals: goals.map(item => item.value), 
            gradYear: gradYear, 
            interests: interests.map(item => item.value), 
            lastName: lastName,
            mentor: mentor, 
            professional: professional,
            profilePic: avatarURL,
            skills: skills.map(item => item.value), 
            title: title ? title : null,
            university: university, 
            volunteer: volunteer,
            zip: zip,
            gender: (gender == 1) ? "Male" : "Female",
            organizations: ['Mecca'],
            joined: serverTimestamp(),
          });

          batch.update(doc(db, "Organizations/Mecca"), {
            [`memberDataMap.${res.user.uid}`] : {email, firstName, lastName, profilePic: avatarURL, joined: serverTimestamp()},
            members: arrayUnion(res.user.uid),
          });

          await batch.commit();
          
          let milli = 0;
          if (toast.isActive("toasts")) {
              toast.close("toasts");
              milli = 100;
          }
          setTimeout(function() {
            toast({
              id: "toasts",
              title: "Account created",
              status: "success",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
          }, milli);
  
          navigate(LOGIN);
        } catch (error) {
          if(res) res.user.delete();
          if(avatarURL) deleteObject(fileRef);

          let milli = 0;
          if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
          }
          setTimeout(function() { 
            toast({
              id: "toasts",
              title: "Signing Up failed",
              description: error.message,
              status: "error",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
          }, milli);
        } finally {
          setLoading(false);
        }
      }
    
  
    return { register, isLoading };
  }

  export function useForgot() {
    const [isLoading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
  
    async function forgot({ email }) {
      setLoading(true);
  
      try {
        await sendPasswordResetEmail(auth, email);

        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
            toast({
                id: "toasts",
                title: "Request Sent",
                status: "success",
                isClosable: true,
                position: "top",
                duration: 5000,
            });
        }, milli);
        navigate(LOGIN);
      } catch (error) {
        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
            toast({
                id: "toasts",
                title: "Request Failed",
                description: error.message,
                status: "error",
                isClosable: true,
                position: "top",
                duration: 5000,
            });
        }, milli);
      } finally {
        setLoading(false);
      }
    }
  
    return { forgot, isLoading };
  }

  export function useLogout() {
    const [signOut, isLoading, signOutError] = useSignOut(auth);
    const toast = useToast();
    const navigate = useNavigate();
  
    async function logout() {
      try {
        await signOut();
        
        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
          toast({
            id: "toasts",
            title: "Successfully logged out",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }, milli);
        navigate(LOGIN);
      } catch (error) {
        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
          toast({
            id: "toasts",
            title: "Logging Out failed",
            description: error.message + "\n" + signOutError.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }, milli);
      }
    }
  
    return { logout, isLoading };
  }