import { Box, Button, Icon, IconButton, Image, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { useToggleAttending } from "hooks/events";
import { EVENTCHAT } from "lib/routes";
import { FiMessageSquare } from "react-icons/fi";
import { MdOutlineCoPresent, MdOutlinePersonalVideo, MdPersonOutline, MdPublic, MdPublicOff } from "react-icons/md";
import { Link } from "react-router-dom";

export function Event({ currentUser, event, id, orgData }) {
  
  const isAttending = event.attending.includes(currentUser.id);
  const isWaitlist = event.waitlist.includes(currentUser.id);
  const config = {
    id,
    isAttending, 
    isWaitlist,
    user: currentUser,
    full: event.full,
  }
 
  const {toggleAttending, isLoading} = useToggleAttending(config);

  const attendingButtonColor = useColorModeValue("yellow", "orange");

  return (
    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' m={5}>
      <Image src={event.image} />

      <Box p='6'>
        <Box display='flex' alignItems='baseline' justifyContent='space-between'>
          <Box
            color='gray.500'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='xs'
            textTransform='uppercase'
            ml='2'
          >
            {event.startDateTime.toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})} {event.endDateTime && `- ${event.endDateTime}`}
            <Text align="left">{event.address}</Text>
          </Box>

          <Tooltip label={event.access ? "Public" : "Private"} placement="top">
          <span style={{ zIndex: 1, position: 'relative' }}>
            <Icon
              boxSize={5}
              ml='auto'
              as={event.access ? MdPublic : MdPublicOff}
              color={event.access ? "green" : "red"}
            />
          </span>
          </Tooltip>
        </Box>

        <Box
          mt='1'
          fontWeight='semibold'
          as='h4'
          lineHeight='tight'
          noOfLines={1}
          display='flex' 
          alignItems='baseline'
          justifyContent='space-between'
        >
          {event.title}

          <Tooltip label={event.location} placement="bottom">
          <span style={{ zIndex: 1, position: 'relative' }}>
            <Icon
              boxSize={5}
              ml='auto'
              as={event.location == "Online" ? MdOutlinePersonalVideo : event.location == "In Person" ? MdPersonOutline : MdOutlineCoPresent}
              color="orange"
            /> 
          </span>
          </Tooltip>
        </Box>


        {event.max != 0 && <Box display='flex' alignItems='baseline'>
          {event.max} people max
        </Box> }

        <Box display='flex' mt='2' alignItems='center'>
          {!event.full ? 
          <>
            <Button colorScheme={isAttending ? attendingButtonColor : null} onClick={toggleAttending} isLoading={isLoading}>{isAttending?"Attending":"Attend"}</Button>
            <Box as='span' ml='2' color='gray.600' fontSize='sm'>
              {event.attending.length} {event.attending.length == 1 ? "Person" : "People"} attending
            </Box>
          </>
          : 
          <>
            <Button colorScheme={isWaitlist ? attendingButtonColor : null} onClick={toggleAttending} isLoading={isLoading}>{isWaitlist?"Waitlisted":"Waitlist"}</Button>
            <Box as='span' ml='2' color='gray.600' fontSize='sm'>
              {event.waitlist.length} {event.waitlist.length == 1 ? "Person" : "People"} waitlisted
            </Box>
          </>
          }

          {isAttending &&
          <IconButton
            ml='auto'
            size='lg'
            variant='ghost'
            as={Link}
            to={`${EVENTCHAT}/${id}`}
            colorScheme={attendingButtonColor}
            icon={<FiMessageSquare/>}
          />
          }
        </Box>
      </Box>
    </Box>
  );
}
