import { Flex, HStack } from "@chakra-ui/layout";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import Avatar from "components/profile/Avatar";
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, IconButton, Text, useBreakpointValue, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { CHAT } from "lib/routes";
import { FiMenu } from "react-icons/fi";

export default function Sidebar() {
  const navigate = useNavigate();
  const [user, CHATS] = useOutletContext();

  const {chats, isLoading: chatsLoading} = CHATS;

  const hoverColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const isDrawerSidebar = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const redirect = (id) => {
    navigate(`${CHAT}/${id}`);
  }
  
  return (
    <Flex h='85vh'>
      {isDrawerSidebar ? (
      <>
    <IconButton
      icon={<FiMenu />}
      
      onClick={onOpen}
    />
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
          <Flex overflowY="auto" direction="column" sx={{scrollbarWidth: "none"}} flex={1} >
        {!chatsLoading && chats.docs.map(chat => {
          let u;
          let id;
          
          for (let key of Object.keys(chat.data().userDataMap)) {
            if (key !== user.id) {
              id = key;
              u = chat.data().userDataMap[key];
              break;
            }
          }

          return (
          <Flex
          key={chat.id}
          p={3}
          align="center"
          _hover={{ bg: hoverColor, cursor: "pointer" }}
          onClick={() => redirect(chat.id)}
          >
          <HStack spacing="12px">
            <Avatar id={id} user={u} size="md" />
            <Text>{`${u.firstName} ${u.lastName}`}</Text>
          </HStack>
        </Flex>
        )})}
      </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    </>
) : (
    <Flex
      h="100%"
      w="300px"
      borderRight="1px"
      borderRightColor={borderColor}
      direction="column"
    >
      <Flex overflowY="auto" direction="column" sx={{scrollbarWidth: "none"}} flex={1} >
        {!chatsLoading && chats.docs.map(chat => {
          let u;
          let id;
          
          for (let key of Object.keys(chat.data().userDataMap)) {
            if (key !== user.id) {
              id = key;
              u = chat.data().userDataMap[key];
              break;
            }
          }

          return (
          <Flex
          key={chat.id}
          p={3}
          align="center"
          _hover={{ bg: hoverColor, cursor: "pointer" }}
          onClick={() => redirect(chat.id)}
          >
          <HStack spacing="12px">
            <Avatar id={id} user={u} size="md" />
            <Text>{`${u.firstName} ${u.lastName}`}</Text>
          </HStack>
        </Flex>
        )})}
      </Flex>
    </Flex>)}
    {!chatsLoading && <Outlet context={[user, CHATS]}/>}
</Flex>
  )
}