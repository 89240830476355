import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "lib/routes";
import { UserContext, setGlobalState } from "lib/constants";
import { useCurrentUser } from "hooks/users";

export default function App() {
  const {user, isLoading: userLoading} = useCurrentUser();
  

  const organization = localStorage.getItem("organization");
  if(organization) setGlobalState("organization", organization);
  

  return (
    <UserContext.Provider value={{user, userLoading}}>
      <ChakraProvider>
        <RouterProvider router={router} />
      </ChakraProvider>
    </UserContext.Provider>
  );
}
