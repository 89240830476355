import { Badge, Box, Button, Flex, Spacer, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "lib/firebase";
import { CHAT } from "lib/routes";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useOutletContext } from "react-router-dom";

function Card({ variant = "panel", children, ...rest }) {
    const bg = useColorModeValue("#F3F3F3", "gray.700");
    const boxShadow = "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)";
    const borderRadius = "15px";
  
    const baseStyle = {
      p: "22px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      minWidth: "0px",
      wordWrap: "break-word",
      backgroundClip: "border-box",
    };
  
    const variants = {
      panel: {
        bg,
        width: "100%",
        boxShadow,
        borderRadius,
      },
    };
  
    const styles = { ...baseStyle, ...variants[variant] };
  
    return (
      <Box __css={styles} {...rest}>
        {children}
      </Box>
    );
  }

  function CardBody({ variant = "default", children, ...rest }) {
    const styles = {
      baseStyle: {
        display: "flex",
        width: "100%",
      },
    };
    
    return (
      <Box __css={styles} {...rest}>
        {children}
      </Box>
    );
  }
  
  function CardHeader({ variant = "default", children, ...rest }) {
    const styles = {
      baseStyle: {
        display: "flex",
        width: "100%",
      },
    };
    
    return (
      <Box __css={styles} {...rest}>
        {children}
      </Box>
    );
  }
  

function TablesTableRow({user, userID, currentUser, CHATS}) {
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const navigate = useNavigate();

    const {chats, isLoading: chatsLoading} = CHATS;

    function areArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
    
      const map = new Map();
    
      for (let val of arr1) {
        if (map.has(val)) {
          map.set(val, map.get(val) + 1);
        } else {
          map.set(val, 1);
        }
      }
    
      for (let val of arr2) {
        if (!map.has(val)) {
          return false;
        } else {
          map.set(val, map.get(val) - 1);
        }
      }
    
      for (let count of map.values()) {
        if (count !== 0) {
          return false;
        }
      }
    
      return true;
    }
  
    return (
      <Tr>
        <Td minWidth={{ sm: "250px" }} pl="0px">
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Avatar user={user} id={userID} size="md"/>
            <Spacer />
            <Flex direction="column">
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {user.firstName + " " + user.lastName}
              </Text>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                {user.email}
              </Text>
            </Flex>
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
          </Flex>
        </Td>
  
        <Td>
          <Badge
            bg={user.status ? "green.400" : bgStatus}
            color={user.status ? "white" : colorStatus}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {user.status?"Online":"Offline"}
          </Badge>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {user.joined?.toDate().toLocaleDateString()}
          </Text>
        </Td>
        <Td>
        {(!chatsLoading && userID != currentUser.id) && <Button p="0px" bg="transparent" variant="no-hover" onClick={() => {
          const chat = chats.docs.find(chat => areArraysEqual(chat.data().users, [userID, currentUser.id]));
          if(!chat) {
            addDoc(collection(db, `chats`), {
              users: [userID, currentUser.id], 
              userDataMap: {[currentUser.id] : {firstName: currentUser.data().firstName, lastName:currentUser.data().lastName, profilePic: currentUser.data().profilePic}, [userID] : {firstName: user.firstName, lastName: user.lastName, profilePic: user.profilePic},}, 
              messages: [],
              lastActive: serverTimestamp(),
            }).then(doc => {
              navigate(`${CHAT}/${doc.id}`);   
            });
          } else {
            navigate(`${CHAT}/${chat.id}`);
          } 
        }}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Message
          </Text>
        </Button>}
      </Td>
      </Tr>
    );
  }

export default function Members() {
    const [currentUser, CHATS, POSTS, ORGDATA] = useOutletContext();
    const textColor = useColorModeValue("gray.700", "white");

    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Members
          </Text>
        </CardHeader>
        <CardBody>
        {/* <InfiniteScroll
                dataLength={users.docs.length}
                next={() => {uSetLimit(prev => prev + 20)}}
                hasMore={uHasMore}
                loader={<Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='md'/>}
              >  */}
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {["Member", "Status", "Joined", ""].map((caption, idx) => {
                  return (
                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
              
            <Tbody>             
              {!ORGDATA.isLoading && ORGDATA.data.members.map(id => {
                const user = ORGDATA.data.memberDataMap[id];
                return (
                  <TablesTableRow
                    key={`${user.email}-${user.firstName}-${user.lastName}`}
                    user={user}
                    userID={id}
                    currentUser={currentUser}
                    CHATS={CHATS}
                  />
                );
              })}
            </Tbody>
          </Table>
          {/* </InfiniteScroll> */}
        </CardBody>
      </Card>
    );
  };