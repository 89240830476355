import { createContext } from "react";
import { createGlobalState } from "react-hooks-global-state";

export const { setGlobalState, useGlobalState } = createGlobalState({
    organization: "Mecca",
});

export function changeOrg(Organization) {
    setGlobalState("organization", Organization);
    localStorage.setItem("organization", Organization);
}

export const UserContext = createContext(null); 
