import { Box, Button, Center, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Textarea, useDisclosure } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import EventsList from "./EventsList";
import { useState } from "react";
import { useAddEvent } from "hooks/events";

export default function EventsPage() {
  const [user, CHATS, POSTS, ORGDATA, ORGCHAT, {events, hasMore, setLimit, isLoading: eventsLoading}] = useOutletContext();
      
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const { addEvent, isLoading } = useAddEvent();

  const [previewSrc, setPreviewSrc] = useState("https://images.vexels.com/media/users/3/131734/isolated/preview/05d86a9b63d1930d6298b27081ddc345-photo-preview-frame-icon.png");

  function handleAddEvent(data) {
    addEvent(data);
    setPreviewSrc("https://images.vexels.com/media/users/3/131734/isolated/preview/05d86a9b63d1930d6298b27081ddc345-photo-preview-frame-icon.png");
    reset();
  }

  return (
    <>
      {!ORGDATA.isLoading && ORGDATA.data.admins.includes(user.id) ? <Box maxW="600px" mx="auto" py="10">
      <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader>Create new event</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
            <form onSubmit={handleSubmit(handleAddEvent)}>
                <FormControl mt={4} isInvalid={errors.eventImage} isRequired>
                    <FormLabel>Event Image</FormLabel>
                    <Image
  src={previewSrc}
  alt="descriptive text"
  cursor="pointer"
  onClick={() => document.getElementById("fileInput").click()}
/>
                    <Input
                    id="fileInput"
            type="file"
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            opacity="0"
            aria-hidden="true"
            accept="image/*"
            {...register("eventImage", {required: {value: true, message: "Required"}})}
            onChange={e => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  setPreviewSrc(event.target.result);
                };
                reader.readAsDataURL(file);
              }}
            }
          />
                    <FormErrorMessage>
          {errors.eventImage && errors.eventImage.message}
        </FormErrorMessage>
                </FormControl> 

                <FormControl>
<HStack mt={4} align="baseline">
  <FormLabel>Public or Private</FormLabel>
  <Switch colorScheme='yellow' {...register("access")}/>
</HStack>
<FormHelperText>
  Choose whether the event is available to only people inside of your organization
</FormHelperText>
</FormControl>

                <FormControl mt={4} isInvalid={errors.location} isRequired>
                    <FormLabel>In Person, Online or Hybrid</FormLabel>
                    <Select {...register('location', { required: true })}>
                        <option value="In Person">In Person</option>
                        <option value="Online">Online</option>
                        <option value="Hybrid">Hybrid</option>
                    </Select>
                    <FormErrorMessage>
          {errors.location && errors.location.message}
        </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.startDateTime} mt={4} isRequired>
                <FormLabel>Date and Time</FormLabel>
            <Input
              {...register("startDateTime", {required: {value: true, message: "Required"}})}
              type="datetime-local"
            />
            <FormErrorMessage>
              {errors.startDateTime && errors.startDateTime.message}
            </FormErrorMessage>
          </FormControl>

                <FormControl isInvalid={errors.title} mt={4} isRequired>
                    <FormLabel>Event Title</FormLabel>
                    <Input {...register('title',  {required: {value: true, message: "Required"}})} />
                    <FormErrorMessage>
              {errors.title && errors.title.message}
            </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.address} mt={4} isRequired>
                    <FormLabel>Address</FormLabel>
                    <Input {...register('address',  {required: {value: true, message: "Required"}})} />
                    <FormErrorMessage>
              {errors.address && errors.address.message}
            </FormErrorMessage>
                </FormControl>

                <FormControl mt={4} isInvalid={errors.maxPeople}>
                    <FormLabel>Maximum number of people</FormLabel>
                    <Input type="number" {...register('maxPeople', { min: 0 })} min={0} />
                    <FormErrorMessage>
          {errors.maxPeople && errors.maxPeople.message}
        </FormErrorMessage>
                </FormControl>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button type="submit" isLoading={isLoading} loadingText="Loading" colorScheme="yellow">Create</Button>
                </ModalFooter>
            </form>
        </ModalBody>
    </ModalContent>
</Modal>
        <Center>
        <HStack>
          <Heading size="lg">Events</Heading>
          <Button
            colorScheme={"yellow"}
            onClick={onOpen}
          >
            Create new event
          </Button>
        </HStack>
        </Center>
    </Box> : <Center>
      <Heading size="lg">Events</Heading>
      </Center>}
      {!eventsLoading && <EventsList currentUser={user} events={events} hasMore={hasMore} setLimit={setLimit} orgData={ORGDATA.data}/>}
    </>
  );
}
