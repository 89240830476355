import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { formatDistanceToNow } from "date-fns";

export default function Header({ post }) {
  const { date, user } = post;
 
  return (
    <Flex
      alignItems="center"
      borderBottom="2px solid"
      borderColor={useColorModeValue('yellow.100', 'orange.300')}
      p="3"
      bg={useColorModeValue('gray.50', 'gray.700')}
    >
      <Avatar user={user} size="md" />

      <Box ml="4">
        <Text>{`${user.firstName} ${user.lastName}`}</Text>
        <Text fontSize="sm" color="gray.500">
          {formatDistanceToNow(date?date.toDate():new Date())} ago
        </Text>
      </Box>
    </Flex>
  );
}
