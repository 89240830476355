import React, { useContext } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  List,
  useColorMode,
  Spinner,
} from '@chakra-ui/react';
import {
  FiHome,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiLayout,
  FiSend,
  FiUsers,
  FiMessageSquare,
} from 'react-icons/fi';
import { TfiAnnouncement } from 'react-icons/tfi';
import { BsCalendar4Event, BsMoonStarsFill, BsSun } from 'react-icons/bs';
import { Outlet, Link as RouterLink, useNavigate } from "react-router-dom";
import { ANNOUNCEMENTS, CHAT, EVENTS, HOME, LOGIN, MEMBERS, ORGCHAT, POSTS, PROFILE } from 'lib/routes';
import { useEffect } from 'react';
import Avatar from 'components/profile/Avatar';
import { useLogout } from 'hooks/auth';
import { UserContext, changeOrg, useGlobalState } from 'lib/constants';
import { useAllOrganizationsData, useCurrentOrganizationData, useOrgChat } from 'hooks/organization';
import Select from 'react-select';
import { useChatsFromUser } from 'hooks/chat';
import { useAnnouncements, usePosts } from 'hooks/posts';
import { useEvents } from 'hooks/events';

function ColorModeToggle(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <IconButton
      size="lg"
      variant="ghost"
      aria-label="Toggle Color Mode"
      onClick={toggleColorMode}
      icon={colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
      {...props}
    />
  );
}

export default function SidebarWithHeader() {
  const [organization] = useGlobalState("organization");

  const {user, userLoading} = useContext(UserContext);

  const ORGDATA = useCurrentOrganizationData();

  const ORGCHAT = useOrgChat();
  
  const CHATS = useChatsFromUser(user ? user.id : "asagd");

  const ANNOUNCEMENTS = useAnnouncements();

  const POSTS = usePosts();

  const EVENTS = useEvents(user?.data().organizations.includes(organization));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(POSTS)
  }, [POSTS.isLoading]);

  useEffect(() => {
    if(!userLoading && !user) {
      navigate(LOGIN);
    }
  }, [user, userLoading]);

  return (
    <Box minH="100vh" bg={useColorModeValue('white', 'gray.900')}>
      <SidebarContent
        onClose={onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} user={user}/>
      <Box ml={{ base: 0, md: 60 }} p="4">
        {!userLoading &&  <Outlet context={[user, CHATS, POSTS, ORGDATA, ORGCHAT, EVENTS, ANNOUNCEMENTS]}/>}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const {colorMode} = useColorMode();
  const user = useContext(UserContext);

  const [Logo] = useGlobalState("organization");
  const {data, isLoading: isDataLoading} = useAllOrganizationsData();

  const LinkItems = [
    {name: 'Home', icon: FiHome, link: HOME},
    {name: 'Members', icon: FiUsers, link: MEMBERS},
    {name: 'Announcements', icon: TfiAnnouncement, link: ANNOUNCEMENTS},
    {name: 'Posts', icon: FiLayout, link: POSTS},
    {name: 'Events', icon: BsCalendar4Event, link: EVENTS},
    {name: 'Group Chat', icon: FiMessageSquare, link: ORGCHAT},
  ];

  const MenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;
  
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={35}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };


  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'black' : 'white',
      zIndex: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
      fontSize: '30px',
      border: 0,
      boxShadow: 'none',
      padding: '10px 0',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 6px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: '5px 0',
    }),
  };


  const organizationOptions = data?.Organizations.map((Organization) => ({
    label: Organization,
    value: Organization,
  }));

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Select
          components={MenuList}
          styles={customStyles}
          value={{label: Logo, value: Logo,}}
          onChange={(item) => {
            changeOrg(item.value);
          }}
          options={organizationOptions}
          isLoading={isDataLoading}
          placeholder={Logo}
        />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link} onClick={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};



const NavItem = ({ icon, children, link, ...rest }) => {
  return (
    <Link as={RouterLink} to={link} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, user, ...rest }) => {
  const { logout } = useLogout()
  const [Logo] = useGlobalState("organization");

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        {Logo}
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <ColorModeToggle/>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <IconButton
          as={RouterLink}
          to={CHAT}
          size="lg"
          variant="ghost"
          aria-label="chat"
          icon={<FiSend />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                {user && <Avatar user={user} size='sm'/>}
          
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{user?.data().firstName + " " + user?.data().lastName}</Text>
                  
                  {/*<Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>*/}
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem
                as={RouterLink}
                to={`${PROFILE}/${user?.id}`}
              >
                Profile
              </MenuItem>
              {/* <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem> */}
              <MenuDivider />
              <MenuItem
                onClick={() => logout()}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};