import { useToast } from "@chakra-ui/react";
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { useGlobalState } from "lib/constants";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

export function useAddComment(currentUser, postID) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  async function addComment(text) {
    setLoading(true);

    try {
    const batch = writeBatch(db);

    batch.set(doc(collection(db, `Organizations/${organization}/posts/${postID}/comments`)), { 
      text, 
      postID, 
      uid: currentUser.id, 
      orgID: organization, 
      userData: {id: currentUser.id, firstName: currentUser.data().firstName, lastName: currentUser.data().lastName, profilePic: currentUser.data().profilePic}, 
      date: serverTimestamp(),
    });

    batch.update(doc(db, `Organizations/${organization}/posts/${postID}`), {
      commentsLength: increment(1),
    });

    await batch.commit();

    let milli = 0;
    if (toast.isActive("toasts")) {
      toast.close("toasts");
      milli = 100;
    }
    setTimeout(function() {
    toast({
      id: "toasts",
      title: "Comment added!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    })
    }, milli);
    } catch (error) {
      let milli = 0;
      if (toast.isActive("toasts")) {
          toast.close("toasts");
          milli = 100;
      }
      setTimeout(function() { 
          toast({
              id: "toasts",
              title: "Comment not added",
              description: error.message,
              status: "error",
              isClosable: true,
              position: "top",
              duration: 5000,
          });
      }, milli);
    } finally {
      setLoading(false);
    }

  }

  return { addComment, isLoading };
}

export function useComments(postID) {
  const [organization] = useGlobalState("organization");
  const [num, setLimit] = useState(20);
  const [hasMore, setMore] = useState(false);
  const [comments, isLoading, error] = useCollection(query(collection(db, `Organizations/${organization}/posts/${postID}/comments`), orderBy("date", "asc")));
  
  const [max, setMax] = useState(0);

  useEffect(() => {
    async function fetch() {
      setMax((await getCountFromServer(query(collection(db, `Organizations/${organization}/posts/${postID}/comments`), orderBy("date", "asc")))).data().count);
      setMore(max ? true : false);
    }

    fetch();
  }, [organization]);

  useEffect(() => {
    if(num >= 500 || max != 0 && num >= max) {
      setMore(false);
    } 
  }, [num, max]);
  
  if(error) console.error(error);

  return { comments, max, hasMore, setLimit, isLoading };
}

export function useUserComments(uid) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const [postComments, setPostComments] = useState([]);
  const [num, setLimit] = useState(20);
  const [hasMore, setMore] = useState(false);
  const [comments, commentsLoading, error] = useCollection(query(collectionGroup(db, "comments"), where("orgID", "==", organization), where("uid", "==", uid), orderBy("date", "desc"), orderBy("postID", "desc"), limit(num)));

  const [max, setMax] = useState(0);

  useEffect(() => {
    async function fetch() {
      setMax((await getCountFromServer(query(collectionGroup(db, "comments"), where("orgID", "==", organization), where("uid", "==", uid), orderBy("date", "desc"), orderBy("postID", "desc")))).data().count);
      setMore(true);
    }

    fetch();
  }, [organization]);

  useEffect(() => {
    if(num >= 500 || max != 0 && num >= max) {
      setMore(false);
    } 
  }, [num, max]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const groupedComments = comments.docs.reduce((result, comment) => {
        if(result.length === 0 || result[result.length - 1][0].data().postID !== comment.data().postID) {
          result.push([]);
        }
        result[result.length - 1].push(comment);
        return result;
      }, []);

      const pc = await Promise.all(groupedComments.map(async gComments => {
        const p = {post: await getDoc(doc(db, `Organizations/${organization}/posts/${gComments[0].data().postID}`)), comments: gComments};
        return p;
      }));

      setPostComments(pc);
      setLoading(false)
    }

    if (!commentsLoading) {
      if (comments) fetchData();
      else setLoading(false); 
    }
  }, [organization, comments, commentsLoading]);

  if (error) console.error(error);

  return { postComments, max, hasMore, setLimit, isLoading };
}

export function useDeleteComment(id, postID) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  async function deleteComment() {
    const res = window.confirm("Are you sure you want to delete this comment?");

    if (res) {
      setLoading(true);
      try {
      const batch = writeBatch(db);

      batch.delete(doc(db, `Organizations/${organization}/posts/${postID}/comments/${id}`));
      
      batch.update(doc(db, `Organizations/${organization}/posts/${postID}`), {
        commentsLength: increment(-1),
      });

      await batch.commit();
      
      let milli = 0;
      if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
      }
      setTimeout(function() {
        toast({
          id: "toasts",
        title: "Comment deleted!",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      }, milli);
      } catch (error) {
        let milli = 0;
        if (toast.isActive("toasts")) {
            toast.close("toasts");
            milli = 100;
        }
        setTimeout(function() { 
            toast({
                id: "toasts",
                title: "Comment not deleted",
                description: error.message,
                status: "error",
                isClosable: true,
                position: "top",
                duration: 5000,
            });
        }, milli);
      } finally {
        setLoading(false);
      }
    } 
  }

  return { deleteComment, isLoading };
}
