import React, { useState } from 'react';
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Stack,
  Text,
  Link,
  RadioGroup,
  Radio,
  FormErrorMessage,
  Spacer,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  FormHelperText,
  Center,
  CheckboxGroup,
  Checkbox,
  HStack,
  FormLabel,
  Textarea,
  Switch,
  Avatar,
  useColorMode,
  Tooltip,
} from '@chakra-ui/react';
import TextareaAutosize from "react-textarea-autosize";
import { Link as RouterLink } from "react-router-dom";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { LOGIN } from 'lib/routes';
import { birthdayValidate, cityValidate, emailValidate, genderValidate, nameValidate, passwordValidate, zipValidate, profilePicValidate, describesValidate, bioValidate, universityValidate, degreeTypeValidate, degreeValidate } from 'lib/form-validate';
import { useProfileTags, useUniversities } from 'hooks/tags';
import Select, {components} from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { useRegister } from 'hooks/auth';

const Form1 = ({form, avatarSrc, setAvatarSrc}) => {
  const [show, setShow] = useState(false);
  const { register, formState: {errors} } = form;

  const handleClick = () => setShow(!show);
  return (
    <>
      <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="10%">
        Let's Start With The Basics
      </Heading>

      <FormControl align='center' isInvalid={errors.profilePic}>
        <Avatar src={avatarSrc} size='2xl' bg='yellow.500' _hover={{ bg: 'yellow.600' }}>
          <Input
            type="file"
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            opacity="0"
            aria-hidden="true"
            accept="image/*"
            {...register("profilePic", profilePicValidate)}
            onChange={e => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  setAvatarSrc(event.target.result);
                };
                reader.readAsDataURL(file);
              }}
            }
          />
        </Avatar>
        <Center>
        <FormErrorMessage>
          {errors.profilePic && errors.profilePic.message}
        </FormErrorMessage>
        </Center>
      </FormControl>

      <Flex>
        <FormControl mt="5%" mr="5%" isInvalid={errors.firstName}>
          <Input placeholder="First name" {...register("firstName", nameValidate)}/>
          <FormErrorMessage>
              {errors.firstName && errors.firstName.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt="5%" isInvalid={errors.lastName}>
          <Input placeholder="Last name" {...register("lastName", nameValidate)}/>
        </FormControl>
        <FormErrorMessage>
          {errors.lastName && errors.lastName.message}
        </FormErrorMessage>
      </Flex>
      
      <FormControl mt="5%" isInvalid={errors.gender}>
              <RadioGroup>
                <Flex direction='row'>
                <Spacer/>
                <VStack>
                  <Radio  {...register("gender", genderValidate)} colorScheme='blue' value='1'/>
                  <Text>Male</Text>
                  </VStack>
                  <Spacer/>
                  <VStack>
                  <Radio {...register("gender", genderValidate)} colorScheme='pink' value='2'/>
                  <Text>Female</Text>
                  </VStack>
                  <Spacer/>
                </Flex>
              </RadioGroup>
              <FormErrorMessage>
                {errors.gender && errors.gender.message}
              </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email} mt="3%">
            <Input
              type="email"
              placeholder="Email"
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.birth} mt="5%">
            <Tooltip label="Birthday">
            <Input
              placeholder="Birthday"
              {...register("birth", birthdayValidate)}
              type="date"
            />
            </Tooltip>
            <FormErrorMessage>
              {errors.birth && errors.birth.message}
            </FormErrorMessage>
          </FormControl>

      <FormControl mt="5%"  isInvalid={errors.password}>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            {...register("password", passwordValidate)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
      </FormControl>

      <Flex>
        <FormControl mt="5%" mr="5%" isInvalid={errors.city}>
          <Input placeholder="City" {...register("city", cityValidate)}/>
          <FormErrorMessage>
              {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt="5%" isInvalid={errors.zip}>
          <Input placeholder="ZIP Code" {...register("zip", zipValidate)}/>
        </FormControl>
        <FormErrorMessage>
              {errors.zip && errors.zip.message}
          </FormErrorMessage>
      </Flex>
    </>
  );
};

const Form2 = ({form, Universities, universitiesLoading}) => {
    const {colorMode} = useColorMode();

    const { control, formState: {errors}, register, watch } = form;
    const isProfessional = watch("professional");

    const MenuList = (props) => {
      const { options, children, maxHeight, getValue } = props;
      const [value] = getValue();
      const initialOffset = options.indexOf(value) * 35;
    
      return (
        <List
          height={maxHeight}
          itemCount={children.length}
          itemSize={35}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      );
    };

    const universityOptions = Universities.map((university) => ({
      label: university,
      value: university,
    }));

    const degreeTypeOptions = ["Associate degree", "Bachelor's degree", "Master's degree", "Doctoral degree"].map((degreeType) => ({
      label: degreeType,
      value: degreeType,
    }));

    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: colorMode === 'dark' ? 'black' : 'white',
        zIndex: 10,
      }),
      option: (provided, state) => ({
        ...provided,
        whiteSpace: 'nowrap', 
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: colorMode === 'dark' ? 'white' : 'black'
      }),
    };
    
  return (
    <>
      <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="20%">
        Discover Muslim Alumni, & CoWorkers
      </Heading>

      <FormLabel>Education</FormLabel>

      <FormControl isInvalid={errors.university} mt="5%">
  <Controller
    control={control}
    name="university"
    rules={universityValidate}
    render={({ field: { onChange, value } }) => (
      <Select
        components={{ MenuList }}
        styles={customStyles}
        value={universityOptions.find((option) => option.value === value)}
        onChange={(item) => {
          onChange(item.value);
        }}
        options={universityOptions}
        isLoading={universitiesLoading}
        placeholder="University / School"
      />
    )}
  />
  <FormErrorMessage>
    {errors.university && errors.university.message}
  </FormErrorMessage>
</FormControl>

<FormControl isInvalid={errors.degreeType} mt="5%">
  <Controller
    control={control}
    name="degreeType"
    rules={degreeTypeValidate}
    render={({ field: { onChange, value } }) => (
      <Select
        components={{ MenuList }}
        styles={customStyles}
        value={degreeTypeOptions.find((option) => option.value === value)}
        onChange={(item) => {
          onChange(item.value);
        }}
        options={degreeTypeOptions}
        placeholder="Degree Type"
      />
    )}
  />
  <FormErrorMessage>
    {errors.degreeType && errors.degreeType.message}
  </FormErrorMessage>
</FormControl>

<FormControl isInvalid={errors.degree} mt="5%">
  <Input placeholder="Degree" {...register("degree", degreeValidate)} />
  <FormErrorMessage>
    {errors.degree && errors.degree.message}
  </FormErrorMessage>
</FormControl>

<FormControl isInvalid={errors.gradYear} mt="5%">
  <Input
    placeholder="Graduation Year"
    {...register("gradYear", birthdayValidate)}
    type="number"
    min={1900} 
    max={2099} 
    step={1}
  />
  <FormErrorMessage>
    {errors.gradYear && errors.gradYear.message}
  </FormErrorMessage>
</FormControl>

<HStack mt="20%" align="baseline">
  <FormLabel>Professional</FormLabel>
  <Switch colorScheme='yellow' {...register("professional")}/>
</HStack>

{isProfessional && <>
  <FormControl mt="5%" mr="5%" isInvalid={errors.company}>
    <Input placeholder="Company" {...register("company", {required: isProfessional})} />
    <FormErrorMessage>
      {errors.company && errors.company.message}
    </FormErrorMessage>
  </FormControl>
  <FormControl mt="5%" mr="5%" isInvalid={errors.title}>
    <Input placeholder="Your Title" {...register("title", {required: isProfessional})} />
    <FormErrorMessage>
      {errors.title && errors.title.message}
    </FormErrorMessage>
  </FormControl>
</>
}
    </>
  );
};

const Form3 = ({form, ProfileTags, profileTagsLoading}) => {
  const {colorMode} = useColorMode();

  const { control, formState: {errors} } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "goals"
  });

  const goalOptions = ProfileTags.data().goals.map((goal) => ({
    label: goal,
    value: goal,
  }));

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        {props.hasValue ? null : children}
      </components.ValueContainer>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'black' : 'white',
      zIndex: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap', 
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black'
    }),
  };

  return (
    <>
    <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="20%">
    How Can Your Community Help You?
      </Heading>

    <FormControl isInvalid={errors.goals} mt="5%">
        <Controller
    control={control}
    name="goals"
    render={({ field: { value } }) => (
      <Select
        options={goalOptions}
        styles={customStyles}
        isLoading={profileTagsLoading}
        isMulti
        onChange={item => {
          append(item.slice(-1));
        }}
        placeholder="Type Goals ..."
        value={value}
        isOptionDisabled={() => value?.length >= 3}
        components={{ ValueContainer: CustomValueContainer }}
      />
    )}
  />
  <Center>
    <FormHelperText>(Pick Your Top 3 Goals)</FormHelperText>
  </Center>
  <FormErrorMessage>
    {errors.goals && errors.goals.message}
  </FormErrorMessage>
</FormControl>
        {fields.map((field, index) => (
          <Tag
          key={field.id}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field.label}</TagLabel>
          <TagCloseButton onClick={() => remove(index)}/>
        </Tag>
        ))}
    </>
  );
};

const Form4 = ({form, ProfileTags, profileTagsLoading}) => {
  const {colorMode} = useColorMode();

  const { control, formState: {errors}, register } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills"
  });

  const skillOptions = ProfileTags.data().skills.map((skill) => ({
    label: skill,
    value: skill,
  }));

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        {props.hasValue ? null : children}
      </components.ValueContainer>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'black' : 'white',
      zIndex: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap', 
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black'
    }),
  };

  return (
    <>
    <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="20%">
    How Can You Help Your Community?
    </Heading>

    <FormControl isInvalid={errors.skills} mt="5%">
        <Controller
    control={control}
    name="skills"
    render={({ field: { value } }) => (
      <Select
        options={skillOptions}
        styles={customStyles}
        isLoading={profileTagsLoading}
        isMulti
        onChange={item => {
          append(item.slice(-1));
        }}
        placeholder="Type Skills ..."
        value={value}
        isOptionDisabled={() => value?.length >= 5}
        components={{ ValueContainer: CustomValueContainer }}
      />
    )}
  />
  <Center>
    <FormHelperText>(Pick Your Top 5 Skills)</FormHelperText>
  </Center>
  <FormErrorMessage>
    {errors.skills && errors.skills.message}
  </FormErrorMessage>
</FormControl>
        {fields.map((field, index) => (
          <Tag
          key={field.id}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field.label}</TagLabel>
          <TagCloseButton onClick={() => remove(index)}/>
        </Tag>
        ))}

<FormControl>
<HStack mt="20%" align="baseline">
  <FormLabel>Mentor</FormLabel>
  <Switch colorScheme='yellow' {...register("mentor")}/>
</HStack>
<FormHelperText>
  If chosen, you receive a gold ring of honor around your profile image letting others know you are willing to offer advice and or mentorship.
</FormHelperText>
</FormControl>
    </>
  );
};

const Form5 = ({form, ProfileTags, profileTagsLoading}) => {
  const {colorMode} = useColorMode();

  const { control, formState: {errors}, register } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "interests"
  });

  const interestOptions = ProfileTags.data().interests.map((interest) => ({
    label: interest,
    value: interest,
  }));

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        {props.hasValue ? null : children}
      </components.ValueContainer>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'black' : 'white',
      zIndex: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap', 
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? 'gray.800' : 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black'
    }),
  };

  return (
    <>
    <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="20%">
      My Interests
    </Heading>

    <FormControl isInvalid={errors.interests} mt="5%">
        <Controller
    control={control}
    name="interests"
    render={({ field: { value } }) => (
      <Select
        options={interestOptions}
        styles={customStyles}
        isLoading={profileTagsLoading}
        isMulti
        onChange={item => {
          append(item.slice(-1));
        }}
        placeholder="Type Interests ..."
        value={value}
        isOptionDisabled={() => value?.length >= 10}
        components={{ ValueContainer: CustomValueContainer }}
      />
    )}
  />
  <Center>
    <FormHelperText>(Pick Your Top 10 Interests)</FormHelperText>
  </Center>
  <FormErrorMessage>
    {errors.interests && errors.interests.message}
  </FormErrorMessage>
</FormControl>
        {fields.map((field, index) => (
          <Tag
          key={field.id}
          size='md'
          colorScheme='yellow'
          mr='2%'
          mt='2%'
          variant='outline'
        >
          <TagLabel>{field.label}</TagLabel>
          <TagCloseButton onClick={() => remove(index)}/>
        </Tag>
        ))}

<FormControl>
<HStack mt="20%" align="baseline">
  <FormLabel>Volunteer</FormLabel>
  <Switch colorScheme='yellow' {...register("volunteer")}/>
</HStack>
<FormHelperText>
  If chosen, you receive a volenteer badge  of honor in your profile letting organizations and professionals around you know that you are ready, willing and able to assist the community.
</FormHelperText>
</FormControl>
    </>
  );
};

const Form6 = ({form}) => {
  const { register, formState: {errors} } = form;
  
  return (
  <>
    <Heading w="100%" size="md" textAlign={'center'} fontWeight="normal" mb="5%">
      My Bio
    </Heading>

    <FormControl mt="5%" isInvalid={errors.bio}>
      <Textarea
        as={TextareaAutosize}
        resize="none"
        mt="5%"
        placeholder="What's Your Story?"
        minRows={10}
        {...register("bio", bioValidate)}
      />
      <FormErrorMessage>
        {errors.bio && errors.bio.message}
      </FormErrorMessage>
    </FormControl>

    <FormControl mt="15%" mb="25%" isInvalid={errors.describes}>
      <FormLabel>What best describes you?</FormLabel>
      <CheckboxGroup colorScheme='yellow'>
        <Center>
          <HStack align='start' mt='5%'>
            <VStack align='start'>
              <Checkbox {...register("describes", describesValidate)} value='Business Owner'>Business Owner</Checkbox>
              <Checkbox {...register("describes", describesValidate)} value='Scholar'>Scholar</Checkbox>
              <Checkbox {...register("describes", describesValidate)} value='Employee'>Employee</Checkbox>
            </VStack>
            <VStack align='start'>
              <Checkbox {...register("describes", describesValidate)} value='Organization Leader'>Organization Leader</Checkbox>
              <Checkbox {...register("describes", describesValidate)} value='Student'>Student</Checkbox>
            </VStack>
          </HStack>
        </Center>
      </CheckboxGroup>
      <FormErrorMessage>
        {errors.describes && errors.describes.message}
      </FormErrorMessage>
    </FormControl>
  </>
  );
}

const total = 6;

export default function Register() {
  const [step, setStep] = useState(1);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [progress, setProgress] = useState(100/total);
  const form = useForm();
  const {Universities, isLoading: universitiesLoading} = useUniversities();
  const {ProfileTags, isLoading: profileTagsLoading} = useProfileTags();
  const {register, isLoading: registerLoading} = useRegister();
  const {handleSubmit, trigger} = form;
  const {colorMode, toggleColorMode} = useColorMode();

  async function handleRegister(data) {
    await register({
      bio: data.bio, 
      birth: data.birth, 
      city: data.city, 
      company: data.company, 
      degree: data.degree, 
      degreeType: data.degreeType, 
      describes: data.describes, 
      email: data.email, 
      firstName: data.firstName, 
      gender: data.gender, 
      goals: data.goals, 
      gradYear: data.gradYear, 
      interests: data.interests, 
      lastName: data.lastName, 
      mentor: data.mentor, 
      password: data.password, 
      professional: data.professional, 
      profilePic: data.profilePic, 
      skills: data.skills, 
      title: data.title, 
      university: data.university, 
      volunteer: data.volunteer, 
      zip: data.zip,
    })
  }

  const forms = [<Form1 avatarSrc={avatarSrc} setAvatarSrc={setAvatarSrc}/>, <Form2 Universities={Universities} universitiesLoading={universitiesLoading}/>, <Form3 ProfileTags={ProfileTags} profileTagsLoading={profileTagsLoading}/>, <Form4 ProfileTags={ProfileTags} profileTagsLoading={profileTagsLoading}/>, <Form5 ProfileTags={ProfileTags} profileTagsLoading={profileTagsLoading}/>, <Form6 />].map((formT, index) => React.cloneElement(formT, { key: index, form: form}));

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text onClick={toggleColorMode} fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool features ✌️
          </Text>
        </Stack>
       
          <Stack spacing={4}>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        >
        <Progress
          value={progress}
          mb="5%"
          mx="5%"
          colorScheme="yellow"
          ></Progress>
        <form onSubmit={handleSubmit(handleRegister)}>
        {forms[step-1]}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1);
                  setProgress(progress - 100/total);
                }}
                isDisabled={step === 1}
                colorScheme="yellow"
                variant="solid"
                w="7rem"
                mr="5%">
                Back
              </Button>
              <Button
                w="7rem"
                isDisabled={step === total}
                onClick={async () => {
                 if(await trigger()) {
                  setStep(step + 1);
                  if (step === total) {
                    setProgress(100);
                  } else {
                    setProgress(progress + 100/total);
                  }
                  }
                }}
                colorScheme="yellow"
                variant="outline">
                Next
              </Button>
            </Flex>
            {step === total ? (
              <Button
                ml="5%"
                w="7rem"
                colorScheme="red"
                variant="solid"
                type="submit"
                isLoading={registerLoading}
                loadingText="Signing Up"
              >
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
       </form>
      </Box>
      <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link
            as={RouterLink}
            to={LOGIN}
            color={'blue.400'}
          >
            Login
          </Link>
              </Text>
            </Stack>
      </Stack>
        
      </Stack>
    </Flex>
  );
}