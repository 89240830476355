import { Box, Spinner, Text } from "@chakra-ui/react";
import { Post } from "components/posts/Post";
import Comment from "./Comment";
import InfiniteScroll from "react-infinite-scroll-component";

export default function CommentUserList({ currentUser, orgData, postComments, setLimit, hasMore}) {

  return (
    <Box px="4" align="center">
      {postComments?.length === 0 ? (
        <Text textAlign="center" fontSize="xl">
          No comments yet... Feeling a little lonely here.
        </Text>
      ) : (
        <InfiniteScroll
          dataLength={postComments.length}
          next={() => {setLimit(prev => prev + 20)}}
          hasMore={hasMore}
          loader={<Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='md'/>}
        >
        {postComments.map((postComment) => (
          <Box key={`bigger-${postComment.post.id}`}>
            <Post key={postComment.post.id} id={postComment.post.id} post={postComment.post.data()} currentUser={currentUser} orgData={orgData}/>
            {postComment.comments.map((comment) => (
              <Comment key={comment.id} comment={comment} orgData={orgData} currentUser={currentUser}/>
            ))}
          </Box>
        ))}
        </InfiniteScroll>
      )}
    </Box>
  );
}