import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import Header from "./Header";
import Actions from "./Actions";

export function Post({ currentUser, post, id, orgData }) {
  const { text } = post;
  
  return (
    <Box p="2" maxW="600px" textAlign="left">
      <Box border="2px solid" borderColor={useColorModeValue('gray.100', 'gray.600')} borderRadius="md">
        <Header post={post} />

        <Box p="2" minH="100px">
          <Text wordBreak="break-word" fontSize="md">
            {text}
          </Text>
        </Box>

        <Actions post={post} currentUser={currentUser} id={id} orgData={orgData}/>
      </Box>
    </Box>
  );
}
