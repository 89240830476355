import Root from "components/Root";
import Forgot from "components/auth/Forgot";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Chat from "components/chat";
import Sidebar from "components/chat/Sidebar";
import Comments from "components/comments";
import EventsPage from "components/events";
import EventChat from "components/events/eventChat";
import Home from "components/home";
import SidebarWithHeader from "components/layout";
import Members from "components/members";
import OrgChat from "components/organizationChat";
import Posts from "components/posts";
import Dashboard from "components/posts/index copy";
import Profile from "components/profile";
import { createBrowserRouter } from "react-router-dom";

export const ROOT = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGOT = "/forgot";

export const PROTECTED = "/protected";
export const HOME = PROTECTED + "/home";
export const MEMBERS = PROTECTED + "/members";
export const CHAT = PROTECTED + "/chat";
export const POSTS = PROTECTED + "/posts";
export const COMMENTS = PROTECTED + "/comments";
export const PROFILE = PROTECTED + "/profile";
export const ORGCHAT = PROTECTED + "/orgChat";
export const EVENTS = PROTECTED + "/events";
export const EVENTCHAT = PROTECTED + "/eventChat";
export const ANNOUNCEMENTS = PROTECTED + "/announcements";


export const router = createBrowserRouter([
  { path: ROOT, element: <Root /> },
  { path: LOGIN, element: <Login /> },
  { path: FORGOT, element: <Forgot /> },
  { path: REGISTER, element: <Register /> },
  {
    path: PROTECTED,
    element: <SidebarWithHeader/>,
    children: [
      {
        path: HOME,
        element: <Home/>,
      },
      {
        path: ANNOUNCEMENTS,
        element: <Dashboard/>,
      },
      {
        path: MEMBERS,
        element: <Members/>,
      },
      {
        path: CHAT,
        element: <Sidebar/>,
        children: [
          {
            path: ":id",
            element: <Chat/>,
          }
        ]
      },
      {
        path: POSTS,
        element: <Posts/>,
      },
      {
        path: PROFILE + "/:id",
        element: <Profile/>,
      },
      {
        path: COMMENTS + "/:id",
        element: <Comments/>,
      },
      {
        path: ORGCHAT, 
        element: <OrgChat/>
      }, 
      {
        path: EVENTS,
        element: <EventsPage/>
      },
      {
        path: EVENTCHAT + "/:id",
        element: <EventChat/>
      }
    ],
  },
]);
