import { Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import Bottombar from "./Bottombar";
import Topbar from "./Topbar";

export default function Chat() {
    const [user, CHATS] = useOutletContext();
    const { id } = useParams();

    const {chats, isLoading: chatsLoading} = CHATS;

    const userColor = useColorModeValue("yellow.100", "orange.400");
    const otherColor = useColorModeValue("gray.100", "gray.600");

    const bottomOfChat = useRef(null);

    const chat = chats?.docs.find(doc => doc.id == id);
    const messages = chat?.data().messages;
  
    useEffect(() => {
      if(bottomOfChat.current) {
        setTimeout(
          bottomOfChat.current.scrollIntoView({
          block: 'start',
        }), 100);
      }
    }, [messages]);
      
    if(chatsLoading || !chat) return "Loading...";

    let oUser, oUserId;
    for (let key of Object.keys(chat.data().userDataMap)) {
        if (key !== user.id) {
            oUserId = key;
            oUser = chat.data().userDataMap[key];
            break;
        }
    }

    return (
        <Flex flex={1} direction="column">
          <Topbar user={oUser} id={oUserId}/>
  
          <Flex flex={1} direction="column" pt={4} mx={5} overflowY="auto" sx={{scrollbarWidth: "none"}}>
            {messages?.map((msg, index) => {
              const sender = msg.sender !== user.id;
              
              return (
                <HStack spacing='12px' key={index} alignSelf={sender ? "flex-start" : "flex-end"} bg={sender ? otherColor : userColor} w="fit-content" minWidth="100px" borderRadius="lg" p={3} m={1}>
                  <Text>{msg.text}</Text>
                </HStack>
              )
            })}
            <div ref={bottomOfChat}/>
          </Flex>
  
          <Bottombar id={id} user={user}/>
        </Flex>
    )
  }