import { Box, Spinner, Text } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Event } from "./Event";

export default function EventsList({ currentUser, events, hasMore, setLimit, orgData }) {

  return (
    <Box px="4" align="center">
      {events.docs.length === 0 ? (
        <Text textAlign="center" fontSize="xl">
          No events yet... Feeling a little lonely here.
        </Text>
      ) : (
        <InfiniteScroll
          dataLength={events.docs.length}
          next={() => {setLimit(prev => prev + 20)}}
          hasMore={hasMore}
          loader={<Spinner thickness='4px' emptyColor='gray.200' color='blue.500' size='md'/>}
        >
          {events.docs.map(event => <Event key={event.id} currentUser={currentUser} id={event.id} event={event.data()} orgData={orgData}/>)}
        </InfiniteScroll> 
     )}
    </Box>
  );
}
