import { Box, Button, Center, Heading, HStack, Textarea } from "@chakra-ui/react";
import { useAddPost } from "hooks/posts";
import { useGlobalState } from "lib/constants";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import PostsList from "./PostsList";

export default function Dashboard() {
  const [user, CHATS, POSTS, ORGDATA, ORGCHAT, EVENTS, { announcements, hasMore, setLimit, isLoading }] = useOutletContext();
  const [organization] = useGlobalState("organization");
  
  const { register, handleSubmit, reset } = useForm();
  const { addPost, isLoading: addingPost } = useAddPost(user, true);
    
  function handleAddPost(data) {
    addPost(data.text);
    reset();
  }

  return (
    <>
      {ORGDATA.data?.admins.includes(user?.id) ? <Box maxW="600px" mx="auto" py="10">
      <form onSubmit={handleSubmit(handleAddPost)}>
        <HStack justify="space-between">
          <Heading size="lg">New Announcement</Heading>
          <Button
            colorScheme="yellow"
            type="submit"
            isLoading={addingPost}
            loadingText="Loading"
          >
            Announce
          </Button>
        </HStack>
        <Textarea
          as={TextareaAutosize}
          resize="none"
          mt="5"
          placeholder="Create a new announcement..."
          minRows={3}
          {...register("text", { required: true })}
        />
      </form>
    </Box> : <Center mb={10}>
      <Heading size="lg">Announcements</Heading>
      </Center>}
      {!isLoading && <PostsList isAnnouncements={true} currentUser={user} posts={announcements} hasMore={hasMore} setLimit={setLimit} orgData={ORGDATA.data}/>}
    </>
  );
}
