import { useToast } from "@chakra-ui/react";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useGlobalState } from "lib/constants";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

export function usePost(id) {
  const [organization] = useGlobalState("organization");
  const [post, isLoading, error] = useDocument(doc(db, `Organizations/${organization}/posts`, id));
  if(error) console.error(error);

  return { post, isLoading };
}

export function useAddPost(currentUser, isAnnouncement) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  async function addPost(text) {
    setLoading(true);
    try {
    await addDoc(collection(db, `Organizations/${organization}/posts`), {
      text,
      user: {id: currentUser.id, firstName: currentUser.data().firstName, lastName: currentUser.data().lastName, profilePic: currentUser.data().profilePic},
      uid: currentUser.id,
      date: serverTimestamp(),
      likes: [],
      commentsLength: 0,
      isAnnouncement,
    });

    let milli = 0;
      if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
      }
      setTimeout(function() {
      toast({
      id: "toasts",
      title: `${isAnnouncement ? "Announcement" : "Post"} added successfully!`,
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    }, milli);
  } catch (error) {
    let milli = 0;
    if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
    }
    setTimeout(function() { 
        toast({
            id: "toasts",
            title: `${isAnnouncement ? "Announcement" : "Post"} not added`,
            description: error.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
        });
    }, milli);
  } finally {
    setLoading(false);
  }
  }

  return { addPost, isLoading };
}

export function useToggleLike({ id, isLiked, uid }) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");

  async function toggleLike() {
    setLoading(true);
    const docRef = doc(db, `Organizations/${organization}/posts`, id);
    await updateDoc(docRef, {
      likes: isLiked ? arrayRemove(uid) : arrayUnion(uid),
    });
    setLoading(false);
  }

  return { toggleLike, isLoading };
}

export function useDeletePost(id) {
  const [isLoading, setLoading] = useState(false);
  const [organization] = useGlobalState("organization");
  const toast = useToast();

  async function deletePost() {
    const res = window.confirm("Are you sure you want to delete this post?");

    if (res) {
    setLoading(true);

    try {
    await deleteDoc(doc(db, `Organizations/${organization}/posts`, id));

    let milli = 0;
      if (toast.isActive("toasts")) {
        toast.close("toasts");
        milli = 100;
      }
      setTimeout(function() {
        toast({
          id: "toasts",
      title: "Post deleted!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
  }, milli);
    } catch (error) {
      let milli = 0;
      if (toast.isActive("toasts")) {
          toast.close("toasts");
          milli = 100;
      }
      setTimeout(function() { 
          toast({
              id: "toasts",
              title: "Post not deleted",
              description: error.message,
              status: "error",
              isClosable: true,
              position: "top",
              duration: 5000,
          });
      }, milli);
    } finally {
      setLoading(false);
    }
  }
  }

  return { deletePost, isLoading };
}

export function useAnnouncements() {
  const [organization] = useGlobalState("organization");
  const [num, setLimit] = useState(20);
  const [hasMore, setMore] = useState(false);
  const q = query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("isAnnouncement", "==", true), limit(num));
  const [announcements, isLoading, error] = useCollection(q);

  const [max, setMax] = useState(0);

  useEffect(() => {
    async function fetch() {
      const q = query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("isAnnouncement", "==", true));
      setMax((await getCountFromServer(q)).data().count);
      setMore(max ? true : false);
    }

    fetch();
  }, [organization]);

  useEffect(() => {
    if(num >= 500 || max != 0 && num >= max) {
      setMore(false);
    }
  }, [num, max]);

  if(error) console.error(error);  

  return { announcements, max, hasMore, setLimit, isLoading };
}

export function usePosts(uid = null) {
  const [organization] = useGlobalState("organization");
  const [num, setLimit] = useState(20);
  const [hasMore, setMore] = useState(false);
  const q = uid
    ? query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("uid", "==", uid), where("isAnnouncement", "==", false), limit(num))
    : query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("isAnnouncement", "==", false), limit(num));
  const [posts, isLoading, error] = useCollection(q);

  const [max, setMax] = useState(0);

  useEffect(() => {
    async function fetch() {
      const q = uid
        ? query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("uid", "==", uid), where("isAnnouncement", "==", false))
        : query(collection(db, `Organizations/${organization}/posts`), orderBy("date", "desc"), where("isAnnouncement", "==", false));
      setMax((await getCountFromServer(q)).data().count);
      setMore(max ? true : false);
    }

    fetch();
  }, [organization]);

  useEffect(() => {
    if(num >= 500 || max != 0 && num >= max) {
      setMore(false);
    }
  }, [num, max]);

  if(error) console.error(error);  

  return { posts, max, hasMore, setLimit, isLoading };
}
