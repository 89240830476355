import { collection, doc, getCountFromServer, getDoc, limit, onSnapshot, query, where } from "firebase/firestore";
import { useGlobalState } from "lib/constants";
import { auth, db } from "lib/firebase";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useDocumentOnce } from "react-firebase-hooks/firestore"

export function useUser(id) {
    const [user, isLoading, error] = useDocumentOnce(doc(db, `users`, id));
    if(error) console.error(error);

    return { user, isLoading };
}

export function useCurrentUser() {
  const [authUser, authLoading, error] = useAuthState(auth);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
 
  useEffect(() => {
    if (!authLoading) {
      if (authUser) {
        setLoading(true);
        const unsubscribe = onSnapshot(doc(db, `users`, authUser.uid), (docSnapshot) => {
          setUser(docSnapshot);
          setLoading(false);
        }, (error) => {
          console.error(error);
        });

        return () => unsubscribe();
      } else {
        setLoading(false); 
      }
    }
  }, [authUser, authLoading]);

  if(error) console.error(error);

  return { user, isLoading };
}

  // export function useUsers() {
  //   const [organization] = useGlobalState("organization");
  //   const [num, setLimit] = useState(20);
  //   const [hasMore, setMore] = useState(false);
  //   const [users, isLoading, error] = useCollection(query(collection(db, `users`), where("organizations", "array-contains", organization), limit(num)));
    
  //   const [max, setMax] = useState(0);

  //   useEffect(() => {
  //     async function fetch() {
  //       setMax((await getCountFromServer(query(collection(db, `users`), where("organizations", "array-contains", organization)))).data().count);
  //       setMore(max ? true : false);
  //     }

  //     fetch();
  //   }, [organization]);

  //   useEffect(() => {
  //     if(num >= 500 || max != 0 && num >= max) {
  //       setMore(false);
  //     } 
  //   }, [num, max]);

  //   if(error) console.error(error);

  //   return { users, max, hasMore, setLimit, isLoading };
  // }